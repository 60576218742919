<template>
  <v-container>
    <v-row align="center" class="row--35">
    <v-col lg="5" md="5" cols="12">
        <div class="thumbnail">
          <img src="../../assets/images/about/about_logo.png" alt="" />
        </div>
      </v-col>
      <v-col lg="7" md="7" cols="12">
        <div class="about-inner inner">
          <div class="section-title">
          <h2 class="heading-title">데이터에 대한 모든 해답</h2>
            <p class="description" align="justify">
              <!-- 데이터엔지니어스랩은 데이터를 기반으로 한 모든 애플리케이션 개발과 서비스를 제공합니다. 특히 Big Data기반의 ML / Advanced Analytics 분야의 IT 컨설팅과 제품 개발을 주력하고 있습니다. -->
              데이터엔지니어스랩은 데이터 중심의 애플리케이션 개발 및 서비스를 전문으로 제공하는 선도 기업입니다. Big Data와 기계학습(ML), 고급 분석(Advanced Analytics) 분야에서의 깊은 전문성을 바탕으로 IT 컨설팅 및 혁신적 제품 개발을 주력하고 있습니다. 최첨단 기술과 데이터 과학의 결합을 통해, 우리는 비즈니스에 혁신을 가져오고 고객의 성장을 가속화하는 맞춤형 솔루션을 제공합니다.
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        icon: "send",
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
